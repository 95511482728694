import * as React from 'react'
import Layout from '../components/layout'

const ProjectPage = () => {
  return (
    <Layout pageTitle="Project">
      <div className="container">
        <div className="p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">Project</h1>
        </div>
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col">
              <div className="card mt-3 h-100">
                <div className="card-header">
                  <h5 className="mb-0">BAPAS Pekanbaru</h5>
                </div>
                <div className="card-body">
                    <p className="card-text">
                        Fitur:<br/>
                        <ul>
                            <li>Web design</li>
                            <li>Custom develop untuk mengatur web dan berita</li>
                            <li>Custom develop untuk mangatur daftar transfer tahanan</li>
                        </ul>
                    </p>
                    <div className="text-center">
                        <a href="https://bapaspku.com" target="_blank" rel="noreferrer" className="btn btn-primary w-50">Visit site</a>
                    </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mt-3 h-100">
                <div className="card-header">
                  <h5 className="mb-0">KLK Indonesia HR and Payroll System</h5>
                </div>
                <div className="card-body">
                    <p className="card-text">
                        Fitur:<br/>
                        <ul>
                            <li>Web design</li>
                            <li>Custom development untuk mengatur data karyawan dan gaji karyawan</li>
                        </ul>
                    </p>
                    <div className="text-center">
                        <a href="https://webapp.klkgroup.com.my/" target="_blank" rel="noreferrer" className="btn btn-primary w-50">Visit site</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProjectPage
